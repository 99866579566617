<template>

  <v-card class="gift-content-type-item pa-2 ma-3">

    <v-layout wrap :justify-center="$vuetify.breakpoint.xsOnly" @click="buttonPressed(value)" style="cursor:pointer">

      <v-flex xs6 sm2>
        <!-- subheading -->
        <div class="theme-font" :style="{'text-align':($vuetify.breakpoint.xsOnly)?'center':'inherit'}" style="font-size:16px; font-weight:600; color:#555555">
          {{title}}
        </div>
        <img :src="img" width="100%"  />
      </v-flex>

      <v-flex xs12 sm7 class="px-4 py-2">
        <v-layout fill-height justify-center align-center>
          <!-- body-2 -->
          <div class="text-xs-center" style="font-size:15px; font-weight:500;">
              {{description}}
          </div>
        </v-layout>
      </v-flex>

      <v-flex xs12 sm3>
        <v-layout fill-height justify-end align-center>
          <v-btn color="info" @click="buttonPressed(value)">{{btn}}</v-btn>
        </v-layout>
      </v-flex>

    </v-layout>

  </v-card>

</template>

<script>
export default {
  name:'gift-content-type-item',
  props: {
    title: {type:String, default:null},
    value: {type:String, default:null},
    btn: {type:String, default:null},
    description: {type:String, default:null},
    img: {type:String, default:null},
  },
  methods: {

    buttonPressed(e) {
      this.$store.dispatch('setCustomizerContentTypeIntent', e)
      .then(() => this.$router.push('/customizer'))
      .catch((error) => console.log(error))
    },

  }
}
</script>

<style scoped>
  .theme-font {
    font-family: Nunito, sans-serif;
  }
</style>
