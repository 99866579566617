<template>
  <div class="content-type">
    <main-nav></main-nav>
    <v-content style="max-width:900px; margin:auto;">

      <div class="theme-font text-center gift-type-title">Select your content</div>

      <gift-content-type-item v-for="(item, i) in contentTypes" :key="i"
        :title="item.title"
        :value="item.value"
        :img="item.img"
        :btn="item.btn"
        :description="item.description"
      ></gift-content-type-item>

    </v-content>
  </div>
</template>

<script>
  import {contentTypes} from '@/static/customizer'

  import MainNav from '@/components/navs/MainNav.vue'

  import GiftContentTypeItem from '@/components/gifts/GiftContentTypeItem.vue'

  export default {
    name:'gift-content-type',
    components: {
      MainNav,
      GiftContentTypeItem,
    },
    data() {
      return {}
    },
    computed: {
      contentTypes() {
        return contentTypes;
      },
    },
    methods: {

      buttonPressed(e) {
        this.$store.dispatch('setCustomizerContentTypeIntent', e)
        .then(() => this.$router.push('/customizer'))
        .catch((error) => console.log(error))
      },

    }
  }
</script>

<style scoped>

  .gift-type-title {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 18px;
    font-size: 28px;
    color: hsla(0, 0%, 40%, 1);
  }
  @media only screen and (max-width: 500px) {
    .gift-type-title {
      font-size: 22px;
    }
  }

</style>
